<template>
  <v-container>
    <ListFiles
      v-if="selectedItem != undefined"
      @close="listFiles = false"
      @remove="remove($event)"
      :messages="messages"
      :chat="chats[selectedItem]"
      :dialog="listFiles"
    ></ListFiles>

    <v-row justify="center">
      <v-dialog v-model="chatDialog" max-width="600px">
        <v-card class="text-center">
          <v-card-title>
            <span class="headline">Novo Chat</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-chip-group
                    active-class="primary accent-4 white--text"
                    column
                    v-model="chatModal.type"
                  >
                    <v-chip @click="changeTypeChat"> Privado </v-chip>
                    <v-chip @click="changeTypeChat"> Grupo </v-chip>
                    <v-chip @click="changeTypeChat"> Etiqueta </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-show="chatModal.title > 0">
                  <v-text-field v-model="chatModal.title" label="Nome">
                  </v-text-field>
                </v-col>
                <v-col v-show="chatModal.type != 2" cols="12" md="12" sm="12">
                  <v-select
                    v-model="chatModal.members"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxHeight: '400' }"
                    label="Selecione"
                    :multiple="chatModal.type == 1 ? true : false"
                    hint="Escolha os usuarios"
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="storeChat"> Criar </v-btn>
                </v-col>
              </v-row>
              <br />
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-card class="mx-auto mt-10" max-width="800" min-height="550">
      <v-system-bar color="indigo darken-2" dark>
        <v-spacer></v-spacer>
        Chat
      </v-system-bar>
      <v-container fluid>
        <v-row dense>
          <v-col md="4">
            <v-list flat>
              <v-subheader class="text-center">
                <v-chip color="success" @click="chatDialog = true" small>
                  Novo
                </v-chip>
              </v-subheader>
              <v-subheader class="text-center">
                <v-chip-group
                  active-class="deep-purple--text text--accent-4"
                  mandatory
                  v-model="typeChat"
                >
                  <v-chip small> Mensagens </v-chip>
                  <v-chip small> Etiquetas </v-chip>
                </v-chip-group>
              </v-subheader>
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                  v-show="loadingChat"
                ></v-progress-circular>
              </div>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="(item, i) in chats"
                
                  :key="i"
                  @click="getMessages(i)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="item.title != 'privado'"
                      v-text="item.title"
                    ></v-list-item-title>
                    <v-list-item-title
                      v-if="item.title == 'privado'"
                      v-text="
                        item.users[0].id != me.id
                          ? item.users[0].name
                          : item.users[1].name
                      "
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col md="8" class="chat">
            <v-footer
              style="background-color: #ededed"
              v-show="selectedItem != undefined"
            >
              <v-row class="text-center" v-if="selectedItem != undefined">
                <v-menu offset-y top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-show="mensagem.tipo == 'texto'"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon style="position: relative; float: right" x-small>
                        fas fa-ellipsis-v
                      </v-icon>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="chat in chats"
                      :key="'etiqueta ' + chat.id"
                      v-show="chat.type == 'etiquetas'"
                      @click="salvarMensagemEtiqueta(mensagem, chat)"
                    >
                      <v-list-item-title>
                        {{ chat.title }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-spacer />
                <v-col md="11" sm="11">
                  <span
                    v-show="
                      chats[selectedItem].tipo == 'grupo' ||
                        chats[selectedItem].tipo == 'etiquetas'
                    "
                  >
                    {{ chats[selectedItem].titulo }}
                  </span>
                  <span
                    v-for="user in chats[selectedItem].users"
                    :key="user.name"
                    v-show="
                      chats[selectedItem].tipo != 'grupo' && user.id != me.id
                    "
                  >
                    {{ user.name }}
                  </span>
                </v-col>
                <v-spacer />
                <v-icon
                  @click="listFiles = true"
                  v-show="chats[selectedItem].tipo != 'etiquetas'"
                  color="blue"
                >
                  fas fa-folder-open
                </v-icon>
              </v-row>
            </v-footer>
            <v-container
              class="mensagens"
              ref="mens"
              style="
                background-color: #f2f2f2;                
                display: flex;
                overflow: auto;
                flex-direction: column-reverse;
              "
            >
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                  v-show="loadingMessages"
                ></v-progress-circular>
              </div>
              <div>

              
                <v-row
                  v-for="mensagem in messages"
                  :key="mensagem.corpo + mensagem.created_at"
                >
                  <v-col v-if="mensagem" @click.right.prevent="openMenu">
                    <div
                      v-if="mensagem.tipo == 'texto'"
                      :class="
                        mensagem.user_id == me.id ? 'content-user' : 'content'
                      "
                      :id="'mensagem-' + mensagem.created_at"
                    >
                      <v-menu offset-y top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-show="mensagem.tipo == 'texto'"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              style="position: relative; float: right"
                              x-small
                            >
                              fas fa-ellipsis-v
                            </v-icon>
                          </div>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="chat in chats"
                            :key="'etiqueta ' + chat.id"
                            v-show="chat.type == 'etiquetas'"
                            @click="salvarMensagemEtiqueta(mensagem, chat)"
                          >
                            <v-list-item-title>
                              {{ chat.title }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list v-show="me.id == mensagem.user_id">
                          <v-list-item @click="excluirMensagem(mensagem)">
                            Apagar
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <span class="autor">
                        {{ mensagem.user.name }}
                      </span>
                      <br />
                      {{ mensagem.corpo }}

                      <div v-show="mensagem.tipo != 'texto'"></div>
                      <br />
                      <span class="horario">
                        {{ convertDate(mensagem.created_at) }}
                      </span>
                    </div>
                    <div
                      v-if="
                        mensagem.tipo != 'texto' &&
                          mensagem.tipo_midia == 'audio/ogg'
                      "
                      :class="
                        mensagem.user_id == me.id ? 'content-user' : 'content'
                      "
                      :id="'mensagem-' + mensagem.created_at"
                    >
                      <v-row>
                        <v-col md="12">
                          <v-menu offset-y top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-show="mensagem.tipo == 'arquivo'"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon
                                  style="position: relative; float: right"
                                  x-small
                                >
                                  fas fa-ellipsis-v
                                </v-icon>
                              </div>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="chat in chats"
                                :key="'etiqueta ' + chat.id"
                                v-show="chat.type == 'etiquetas'"
                                @click="salvarMensagemEtiqueta(mensagem, chat)"
                              >
                                <v-list-item-title>
                                  {{ chat.title }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                            <v-list v-show="me.id == mensagem.user_id">
                              <v-list-item @click="excluirMensagem(mensagem)">
                                Apagar
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <span class="autor">
                            {{ mensagem.user.name }}
                          </span>
                          <br />
                          <Player :file="mensagem.midia"></Player>
                          <br />
                          <span class="horario">
                            {{ convertDate(mensagem.created_at) }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                    <div
                      v-if="
                        mensagem.tipo != 'texto' &&
                          mensagem.tipo_midia != 'audio/ogg'
                      "
                      :class="
                        mensagem.user_id == me.id ? 'content-user' : 'content'
                      "
                      :id="'mensagem-' + mensagem.created_at"
                    >
                      <v-menu offset-y top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-show="mensagem.tipo == 'arquivo'"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon
                              style="position: relative; float: right"
                              x-small
                            >
                              fas fa-ellipsis-v
                            </v-icon>
                          </div>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="chat in chats"
                            :key="'etiqueta ' + chat.id"
                            v-show="chat.type == 'etiquetas'"
                            @click="salvarMensagemEtiqueta(mensagem, chat)"
                          >
                            <v-list-item-title>
                              {{ chat.title }}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <v-list v-show="me.id == mensagem.user_id">
                          <v-list-item @click="excluirMensagem(mensagem)">
                            Apagar
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <span class="autor"> {{ mensagem.user.name }} </span>
                      <br />
                      <a
                        :href="mensagem.midia"
                        style="text-decoration: none"
                        target="_blank"
                      >
                        <v-chip>
                          <span v-if="mensagem.nameMidia">
                            {{ mensagem.nameMidia }}
                          </span>
                          <v-icon class="pl-4">
                            fas fa-cloud-download-alt
                          </v-icon>
                        </v-chip>
                        <br />
                        <span class="horario">
                          {{ convertDate(mensagem.created_at) }}
                        </span>
                      </a>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-progress-linear
                    v-model="loadingFile.value"
                    :active="loadingFile.active"
                    :indeterminate="loadingFile.indeterminate"
                    :query="loadingFile.query"
                  ></v-progress-linear>
                </v-row>
              </div>
            </v-container>
            <v-footer
              style="background-color: white"
              v-show="
                selectedItem != undefined &&
                  chats[selectedItem].type != 'etiquetas'
              "
            >
              <v-row class="text-center">
                <v-col md="1" sm="1" small>
                  <v-file-input hide-input v-model="arquivo"> </v-file-input>
                </v-col>
                <v-col md="10" sm="10" class="pt-8" v-if="arquivo != undefined">
                  <v-chip> {{ arquivo.name }} </v-chip>
                </v-col>
                <v-col md="10" sm="10" v-else>
                  <v-text-field
                    @keyup.enter="send"
                    v-model="message"
                    label="Digite uma mensagem..."
                    required
                  ></v-text-field>
                </v-col>
                <v-col
                  md="1"
                  sm="1"
                  class="mt-6"
                  small
                  v-if="arquivo != undefined"
                >
                  <v-icon @click="uploadFile"> fas fa-cloud-upload-alt </v-icon>
                </v-col>
                <v-col md="1" sm="1" class="mt-6" small v-else>
                  <v-icon @click="send" v-show="message != ''">
                    fas fa-paper-plane
                  </v-icon>
                  <v-icon
                    @click="getAudio"
                    v-show="message == '' && mediaRecorder == undefined"
                  >
                    fas fa-microphone
                  </v-icon>
                  <span v-show="audioDetected"> {{ i }} </span>
                  <v-icon
                    @click="closeAudio"
                    color="red"
                    v-show="message == '' && mediaRecorder != undefined"
                  >
                    fas fa-microphone
                  </v-icon>
                </v-col>
              </v-row>
            </v-footer>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ChatService from "@/services/Chat/ChatService";
import UserService from "@/services/User/UserService";
import MensagemService from "@/services/Chat/MensagemService";
import ToastService from "@/services/ToastService";
import Player from "@/components/Player/Index";
import ListFiles from "@/components/Dialog/ListFiles";
import { mapGetters } from "vuex";

export default {
  components: {
    Player,
    ListFiles,
  },
  data: () => ({
    menu: true,
    listFiles: false,
    loadingFile: {
      value: 0,
      indeterminate: true,
      active: false,
      query: false,
    },
    typeChat: 0,
    arquivo: undefined,
    chatDialog: false,
    url:
      "https://firebasestorage.googleapis.com/v0/b/wsjus-app.appspot.com/o/chats%2F7%2Faudio.ogg?alt=media",
    i: 0,
    audioRegister: undefined,
    audioDetected: false,
    query: "",
    ChatService: new ChatService(""),
    UserService: new UserService(""),
    MensagemService: new MensagemService(""),
    selectedItem: undefined,
    message: "",
    duration: 0,
    midia: undefined,
    mediaRecorder: undefined,
    audio: undefined,
    items: [
      { text: "Alysson", icon: "mdi-clock" },
      { text: "José", icon: "mdi-account" },
      { text: "Maria", icon: "mdi-flag" },
    ],
    chats: [],
    loadingChat: false,
    users: [],
    chatModal: {
      titulo: "",
      tipo: 0,
      participantes: [],
    },
    messages: [],
    loadingMessages: false,
    audioPlay: false,
    queryUser: "",
    openFiles: false,
  }),
  created: async function() {
    await this.indexData();
    await this.indexUsers();
  },

  computed: {
    ...mapGetters({
      me: "getMe",
    }),
  },
  methods: {
    excluirMensagem(mensagem) {
      try {
        console.log(mensagem)
         window.db
        .collection("chat")
        .doc(mensagem.doc)
        .delete();
      } catch (error) {
        console.log(error)
        ToastService('Erro ao excluir mensagem', 'error')
      }
     
    },
    salvarMensagemEtiqueta(mensagem, etiqueta) {
      var msg = mensagem;
      msg.chat_id = etiqueta.id;

      window.db
        .collection("chat")
        .add(msg)
        .then((res) => {
          console.log(res);
        });
    },
    verifyType(tipo) {
      if (this.typeChat == 0) {
        if (tipo != "etiquetas") {
          return true;
        } else {
          return false;
        }
      } else {
        if (tipo == "etiquetas") {
          return true;
        } else {
          return false;
        }
      }
    },
    async remove(event) {
      try {
        await this.ChatService.storeOrUpdate("chats/remove", {
          user_id: event.user_id,
          chat_id: this.chats[this.selectedItem].id,
        });

        await this.indexData();

        this.listFiles = false;

        ToastService("Ação realizada com sucesso.", "success");
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      }
    },
    download(url) {
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function(event) {
        console.log(event);
        var blob = xhr.response;
        console.log(blob);
      };
      xhr.open("GET", url);
      xhr.send();
    },
    uploadFile() {
      this.loadingFile.active = true;
      var name = Math.random() * 99 * Math.random();

      var ext = this.arquivo.name.split(".");
      let nameOfFile = ext[0];
      ext = ext[ext.length - 1];
      var nameComplete = name + "%%%" + nameOfFile + "." + ext;
      console.log(nameComplete);
      console.log(ext);
      var storageRef = window.storage.ref(
        "chats/" + this.chats[this.selectedItem].id + "/" + nameComplete
      );

      var metadata = {
        contentType: this.arquivo.type,
      };

      this.midia = {
        midia: nameComplete,
        tipo_midia: this.arquivo.type,
        nameMidia: this.arquivo.name,
      };

      let self = this;
      storageRef.put(this.arquivo, metadata).then(function(snapshot) {
        self.loadingFile.indeterminate = false;
        self.loadingFile.value =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        var storage = window.storage;
        var storageRef = storage.ref();

        storageRef
          .child(
            "chats/" + self.chats[self.selectedItem].id + "/" + self.midia.midia
          )
          .getDownloadURL()
          .then(function(uri) {
            console.log("uri" + uri);
            self.midia.midia = uri;
            console.log("name" + self.midia.midia);
            self.send();
          });
      });
    },
    getMime(ext) {
      console.log(ext);
    },
    choiceFile() {
      console.log("oi");
    },
    changeTypeChat() {
      this.chatModal.members = undefined;
    },
    convertDate(created_at) {
      var date = new Date(created_at);

      return date.toLocaleString("pt-BR", {
        hour: "numeric", // numeric, 2-digit
        minute: "numeric", // numeric, 2-digit
        day: "2-digit", // numeric, 2-digit
        year: "2-digit", // numeric, 2-digit
        month: "2-digit", // numeric, 2-digit, long, short, narrow
      });
    },
    async indexData() {
      try {
        this.loadingChat = true;

        const res = await this.ChatService.index(
          "chats?search=" + this.queryUser
        );
        this.chats = res;
      } catch (exception) {
        console.log(exception);
        ToastService(exception, "error");
      } finally {
        this.loadingChat = false;
      }
    },
    async indexUsers() {
      try {
        this.dataLoading = true;

        const res = await this.UserService.index("users?search=" + this.query);
        var self = this;
        res.map(function(element) {
          if (self.me.id != element.id) {
            self.users.push(element);
          }
        });
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.dataLoading = false;
      }
    },
    async storeChat() {
      try {
        if (this.chatModal.type == 0) {
          this.chatModal.type = "privado";
        } else if (this.chatModal.type == 1) {
          this.chatModal.type = "grupo";
        } else if (this.chatModal.type == 2) {
          this.chatModal.type = "etiquetas";
        }

        if (this.chatModal.type == "privado") {
          this.chatModal.title = "privado";
        }

        if (this.chatModal.members == undefined) {
          this.chatModal.members = [this.me.id];
        }

        if (this.chatModal.title == "privado") {
          this.chatModal.members = [this.chatModal.members];
        }

        await this.ChatService.storeOrUpdate("chats", this.chatModal);

        await this.indexData();

        ToastService("Ação realizada com sucesso.", "success");
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      }
    },
    async getMessages(i) {
      try {
        this.loadingMessages = true;
        window.db
          .collection("chat")
          .where("chat_id", "==", this.chats[i].id)
          .orderBy("created_at")
          .onSnapshot((query) => {
            let allMessages = [];
            query.forEach((obj) => {
              allMessages.push({ doc: obj.id, ...obj.data() });
            });

            this.messages = allMessages;
            this.loadingMessages = false;
          });
      } catch (exception) {
        ToastService(exception, "error");
      }
    },
    start() {
      var teste = document.getElementById("teste");
      teste.play();
    },

    stop() {
      var teste = document.getElementById("teste");
      teste.pause();
    },
    async getAudio() {
      this.i = 0;
      this.audioDetected = true;
      var self = this;
      this.audioRegister = setInterval(function() {
        self.i++;
        console.log(self.i);
      }, 1000);
      this.midia = undefined;
      await navigator.mediaDevices.getUserMedia({ audio: true }).then(
        (stream) => {
          this.mediaRecorder = new MediaRecorder(stream);
          let chunks = [];
          this.mediaRecorder.ondataavailable = (data) => {
            chunks.push(data.data);
          };
          this.mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, { type: "audio/ogg; code=opus" });

            this.audio = blob;

            console.log(blob);

            const reader = new FileReader();
            reader.readAsDataURL(blob);

            var name = Math.random() * 99 * Math.random() + ".ogg";
            var storageRef = window.storage.ref(
              "chats/" + this.chats[this.selectedItem].id + "/" + name
            );

            var metadata = {
              contentType: "audio/ogg",
            };

            this.midia = {
              midia: name,
              nameMidia: name,
              tipo_midia: "audio/ogg",
            };

            console.log(this.midia);
            let self = this;
            storageRef.put(this.audio, metadata).then(function(snapshot) {
              console.log(snapshot);
              var storage = window.storage;
              var storageRef = storage.ref();

              storageRef
                .child(
                  "chats/" +
                    self.chats[self.selectedItem].id +
                    "/" +
                    self.midia.midia
                )
                .getDownloadURL()
                .then(function(uri) {
                  console.log("uri" + uri);
                  self.midia.midia = uri;
                  console.log("name" + self.midia.midia);
                  self.send();
                });
            });
            console.log("stop");
          };
          this.mediaRecorder.start();
        },
        (err) => {
          console.log(err);
        }
      );
    },

    closeAudio() {
      clearInterval(this.audioRegister);
      this.audioDetected = false;
      this.i = 0;
      this.mediaRecorder.stop();
      this.midia = undefined;
      this.mediaRecorder = undefined;
    },
    async send() {
      let message = {
        id: this.messages.length + 1,
        user_id: this.me.id,
        corpo: this.message,
        tipo: this.message != "" ? "texto" : "arquivo",
        chat_id: this.chats[this.selectedItem].id,
        created_at: Date.now(),
        user: this.me,
        midia: this.midia ? this.midia.midia : "",
        tipo_midia: this.midia ? this.midia.tipo_midia : "",
        nameMidia:
          this.midia && this.midia.nameMidia != undefined
            ? this.midia.nameMidia
            : "",
      };
      var self = this;
      try {
        window.db
          .collection("chat")
          .add(message)
          .then((res) => {
            console.log(res);
            self.loadingFile.active = false;
            self.loadingFile.indeterminate = true;
            self.loadingFile.value = 0;
            this.arquivo = undefined;
          });
      } catch (error) {
        console.log(error);
      } finally {
        window.scrollTo(0, document.querySelector(".mensagens").scrollBottom);
      }

      this.message = "";
    },
  },
};
</script>

<style scoped>
#tipofile {
  display: none;
}
audio.non-downloadable::-webkit-media-controls-enclosure {
  overflow: hidden;
}
audio.non-downloadable::-webkit-media-controls-panel {
  width: calc(100% + 32px);
}

video::-webkit-media-controls-mute-button {
  overflow: hidden;
  display: none;
}
.audio {
  background-color: transparent !important;
}
.chat {
  display: block;
  height: calc(100vh - 9.5rem);
}

.mensagens {
  position: relative;
  height: 80%;
}

.input-mensagem {
  height: 200px;
  background-color: #ffff !important;
}

.content {
  padding: 8px;
  background-color: #90ee90;
  border-radius: 10px;
  display: inline-block;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  max-width: 50%;
  word-wrap: break-word;
}

.content-user {
  padding: 8px;
  background-color: #87cefa;
  border-radius: 10px;
  display: inline-block;
  -webkit-box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 2px 1px -1px rgb(0 0 0 / 12%);
  max-width: 50%;
  word-wrap: break-word;
  float: right;
}

.autor {
  color: rgb(134, 133, 133);
  font-size: 14pt;
}

.horario {
  float: right;
  color: rgb(134, 133, 133);
  font-size: 10pt;
}
</style>
